import React from "react";
import geneticTestingKit from "../../assets/genetic-testing-kit.png";
import classNames from "classnames";
import { Link } from "react-router-dom";

const GeneticTestingKitCard = ({ isKitCompleted }) => {
  return (
    <div
      className={classNames(
        "genetic-card",
        isKitCompleted && "completed-kit-bg"
      )}
    >
      <div className="genetic-card-content">
        {isKitCompleted ? (
          <>
            <h2 className="title">Your Kit Is On Its Way!</h2>
            <p className="description">
              When we receive your kit at the lab, it will go through multiple
              stages of sequencing and analysis. Once it’s ready, we’ll notify
              you via email for you to review your report. Please note: this process can take up to 6 weeks.
            </p>
            <Link
              to={"/genetic-testing-instruction"}
              className="genetic-card-button"
            >
              Back To Kit Instructions
            </Link>
          </>
        ) : (
          <>
            <h2 className="title">Complete Your Genetic Testing Kit</h2>
            <p className="description">
              Follow the instructions and videos on this page to fill your
              salivary sampling kit and mail it off to our labs via one of our
              dedicated postal locations.
            </p>
            <Link
              to={"/genetic-testing-instruction"}
              className="genetic-card-button"
            >
              Click Here
            </Link>
          </>
        )}
      </div>
      <div className="genetic-card-image">
        <img src={geneticTestingKit} alt="Genetic Testing Kit" />
      </div>
    </div>
  );
};

export default GeneticTestingKitCard;

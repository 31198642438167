import { useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./ProgressCard.css";
import SmoothScrollLink from "../SmoothScrollLink/SmoothScrollLink.tsx";

const ProgressCard = ({ name, score, severity, identifier }) => {
  const capitalize = (str = "") => {
    return `${str?.[0]?.toUpperCase()}${str.slice(1)}`;
  };

  const positiveTrainingListMapping = [
    "Motivation To Exercise",
    "Power Response",
    "Recovery Efficiency",
    "Strength Response",
    "Aerobic (V02) Trainability",
    "Aerobic (VO2) Trainability & recovery",
    "Endurance Response",
    "Muscle Mass Predisposition",
    "Lactose Tolerance"
  ];

  const getColorBar = (value, name) => {
    if (positiveTrainingListMapping.includes(name)) {
      if (value === "low") {
        return "#E57373";
      }

      if (value === "medium") {
        return "#FFCC91";
      }
      if (value === "high") {
        return "#87E1B0";
      }
    }
    if (value === "low") {
      return "#87E1B0";
    }

    if (value === "medium") {
      return "#FFCC91";
    }
    if (value === "high") {
      return "#E57373";
    }
  };

  const getProgressBar = (prog) => {
    if (prog > 100) {
      return 100;
    }
    if (prog < 0) {
      return 1;
    }

    return prog;
  };

  return (
    <SmoothScrollLink className="nutrition-card" to={identifier}>
      <div className="card-top">
        <CircularProgressbar
          value={getProgressBar(score)}
          text={`${getProgressBar(score)}%`}
          className="cpb-text"
          strokeWidth={12}
          styles={buildStyles({
            pathColor: getColorBar(severity, name),
            textColor: "black",
            trailColor: "#EEF0FA",
            strokeLinecap: "butt",
          })}
        />
      </div>
      <div className="card-bottom">
        <div className="card-name">{name}</div>
        <div className="card-severity-container">
          <span className="card-severity">{capitalize(severity)}</span>
          <div className="arrow" />
        </div>
      </div>
    </SmoothScrollLink>
  );
};

export default ProgressCard;

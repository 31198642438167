import { useEffect, useState } from "react";
import classNames from "classnames";
import ActiveDot from "./ActiveDot";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import { Link, useLocation } from "react-router-dom";

const pages = {
  "/settings": "Settings",
  "/admin": "Admin",
  "/user-subscription": "Subscriptions",
  "/myoengine": "Myo-Engine"
};

const AdminNav = ({}) => {
  const [active, setActive] = useState("");
  const { pathname } = useLocation();
  const { data: currentUser } = useCurrentUser();
  const isAdmin = currentUser?.user?.role === "ADMIN";

  useEffect(() => {
    setActive(pathname);
  }, []);

  const filteredPages = Object.entries(pages).filter(([key, value]) => {
    if (key === "/admin" || key === "/user-subscription" || key === '/myoengine') {
      return isAdmin;
    }
    return true;
  });

  return (
    <div className="navigation-list">
      <div className="navigation-list-line" />
      {filteredPages.map(([key, value]) => (
        <div
          key={`navigation-list-${key}`}
          className={classNames(
            "progress-links-item",
            active === key && "active"
          )}
          onClick={() => setActive(key)}
        >
          <ActiveDot active={active === key} />
          <Link key={key} to={key} className="side-navigation-link">
            <span>{value}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default AdminNav;

// axiosInterceptors.js
import axios from "axios";

let activeRequests = 0;

axios.interceptors.request.use(
  (config) => {
    activeRequests++;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    activeRequests--;
    return response;
  },
  (error) => {
    activeRequests--;
    return Promise.reject(error);
  }
);

/** Return the current number of active requests */
export const getActiveRequests = () => activeRequests;

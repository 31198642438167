import React from "react";

export const disclaimer: React.ReactNode = (
  <>
    I confirm I have read the above information and{" "}
    <a
      href="https://www.myoform.io/privacy"
      target="_blank"
      rel="noopener noreferrer"
    >
      Myoform’s privacy policy
    </a>{" "}
    and I consent to the processing of appropriate data. I understand that the content provided in my health report in no way constitutes a substitute for medical advice and/or diagnosis, and I am happy to proceed to my report.
  </>
);

import React, { useState } from "react";
import classNames from "classnames";
import { howToReadContent } from "../../helpers/variables";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { disclaimer } from "./constants";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import axios from "axios";
import { useMutation } from "react-query";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import config from "../../config";

interface HowToReadProps {}

export type Content = Record<string, string>;

const HowToRead: React.FC<HowToReadProps> = ({}) => {
  const renderParagraphs = (content: Content) => {
    const paragraphs = [];
    let i = 1;

    while (content[`paragraph${i}`]) {
      paragraphs.push(<p key={`paragraph${i}`}>{content[`paragraph${i}`]}</p>);
      i++;
    }

    return paragraphs;
  };

  const { data: currentUser, refetch } = useCurrentUser();
  const [isChecked, setIsChecked] = useState(
    currentUser?.user?.has_confirmed_how_to_read
  );

  const [isSubmitted, setIsSubmitted] = useState(
    currentUser?.user?.has_confirmed_how_to_read
  );
  const confirmHowToReadMutation = useMutation(
    async () => {
      const userId = currentUser?.user?.id;
      return axios.post(
        `${config.apiBaseURL}/api/confirm_how_to_read/${userId}`,
        {},
        {
          withCredentials: true,
        }
      );
    },
    {
      onSuccess: () => {
        setIsSubmitted(true);
        refetch();
      },
      onError: (error) => {
        console.error("Error confirming disclaimer:", error);
      },
    }
  );

  const handleSubmit = () => {
    confirmHowToReadMutation.mutate();
  };

  return (
    <div className={classNames("LeftDiv", "how-to-read-wrap")}>
      <h2 className="basic-page-header">{howToReadContent.header}</h2>
      <>
        <section id={"welcome"} className="basic-section">
          <h3>Welcome!</h3>
          <p>
            {`Welcome to the Myoform platform, ${currentUser?.user?.first_name}! We've spent the past 5 years painstakingly collating all the best available research in the world on the science of how our genetics influence our biology in the context of nutrition and exercise – so no matter how you found us, we're so glad you are here and we can't wait to help you uncover the science of YOU!`}
            <br />
            <br />
            {`Before diving into all the juicy details your reports and supplement formula, we've put together a short introduction video for you to get to grips with what this all means and how to get the most out of your data.`}
          </p>

          <div className="how-to-read-video-container">
            <iframe
              src={
                "https://www.youtube.com/embed/6ds8Q5K511Q?si=c0njHavfxQsjytv_"
              }
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded YouTube Video"
            ></iframe>
          </div>
        </section>
        {(howToReadContent.content as Content[]).map((item) => (
          <section
            key={item.identifier}
            id={item.identifier}
            className="basic-section"
          >
            <h3>{item.heading}</h3>
            {renderParagraphs(item)}
          </section>
        ))}
        <section id={"disclaimer"} className="basic-section">
          <h3>Disclaimer</h3>
          <p>
            Regardless of our methodology, it is necessary for us to disclose
            that whilst we always endeavour to utilise the best, currently
            available scientific and clinical literature to advise our
            recommendations - our reporting serves only for educational and
            informational purposes and is not intended as medical advice. Our
            recommendations are not a form of diagnosis, a substitute for
            medical advice, or treatment from a qualified healthcare provider.
            We are not licensed healthcare providers and do not provide medical
            diagnosis, treatment, or advice. We cannot guarantee the accuracy or
            completeness of the information we provide, and you should consult
            with your healthcare provider before making any changes to your diet
            or lifestyle. By using our services and with the selection of the
            check box below, you acknowledge and agree that you release us from
            any and all liability for any adverse effects or consequences
            resulting from the use of our recommendations.
          </p>
          <div className="how-to-read-disclaimer-wrap">
            <CustomCheckbox
              isCheckedStatus={isChecked}
              onClick={() => setIsChecked(!isChecked)}
              text={disclaimer}
              disabled={isSubmitted}
            />
            <ButtonWithIcon
              classNameOverWrite="how-to-read-disclaimer-button"
              classNameIconOverWrite="save-btn-icon"
              text={isSubmitted ? "Submitted" : "Submit"}
              disabled={!isChecked || isSubmitted}
              onClick={handleSubmit}
            />
          </div>
        </section>
      </>
    </div>
  );
};

export default React.memo(HowToRead);

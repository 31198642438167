export const SORT = {
  ALPHA: "Sort A-Z",
  QUANTITY: "Sort High-Low",
};

export const stepsMapping = {
  generalQuestions: "General Questions",
  nutrition: "Nutrition",
  training: "Training",
  sleepAndIllness: "Sleep And Illness",
};

export const howToReadContent = {
  header: "Read Your Report",
  content: [
    {
      identifier: "howToRead1",
      heading: "What Is A Gene?",
      paragraph1:
        "DNA, or deoxyribonucleic acid, is the hereditary material in humans and almost all other organisms. The information in DNA is stored as a code made up of four chemical bases: adenine (A), guanine (G), cytosine (C), and thymine (T). Human DNA consists of about 3 billion bases, and more than 99 percent of those bases are the same in all people. A gene however, is a segment of your DNA (and string of nucleotides) that encodes for the production of a specific type of protein by providing instructions to your body to start piecing together many smaller, constituent molecules called amino acids. These chains of amino acids then fold together (in very specific ways) to create almost every cell in our bodies. Small, interpersonal variations in these genes are what we refer to as 'genotypes' - and it is these genotypes that lead to differences in how we react or our bodies respond to certain stimuli.",
    },
    {
      identifier: "howToRead2",
      heading: "What is an SNP?",
      paragraph1:
        "In this report, we often use the term SNPs (pronounced “snips”) - which stands for 'Single nucleotide polymorphisms'. These are the most common type of genetic variation among people and each SNP represents a difference in a single DNA building block, called a nucleotide. For example, an SNP may replace a specific nucleotide in the sequence of a gene (e.g. cytosine) with thymine (T) in a certain stretch of DNA. SNPs help predict an individual's response to certain nutrients, drugs, susceptibility to environmental factors such as toxins, and risk of developing diseases.SNPs can also be used to track the inheritance of disease- associated genetic variants within families.",
    },
    {
      identifier: "howToRead3",
      heading: "What is Nutrigenetics?",
      paragraph1:
        "Nutrigenetics and nutrigenomics are defined as the science of the effect of genetic variation on dietary response and the role of nutrients and bioactive food compounds in gene expression, respectively. And whilst generally, the science is still in its infancy, hundreds of peer-reviewed papers are published every year on new discoveries that may have an impact on our health and well-being. The Wiley Nutrition report provides information based primarily on your genetics into two key areas, nutrition and physical traits. However, it is worth noting that your genes can only tell you part of the story of your health. There is a multitude of other variables that can also significantly influence an individual's susceptibility to health complications, such as lifestyle and environmental factors. This is why our algorithm includes genetics, survey data, blood testing (if included), and machine learning, to ensure that we provide the ‘gold-standard’ in reporting and continuously optimise our solution.",
    },
    // {
    //   identifier: 'howToRead4',
    //   heading: "Disclaimer",
    //   paragraph1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis rutrum turpis sem, vel sodales ligula venenatis id. Phasellus tristique justo semper, pellentesque libero a, suscipit dolor. Nullam ullamcorper sapien et porta vehicula. Proin ac lacinia erat, nec hendrerit felis. Sed et nulla ut odio hendrerit vulputate. Cras blandit varius massa, id tristique quam tincidunt sit amet. Cras sodales velit et ligula eleifend, non convallis ipsum gravida. Phasellus bibendum nibh libero, ac tincidunt nulla blandit nec. Sed ipsum mi, cursus quis volutpat sed, ullamcorper ut est. Maecenas porttitor scelerisque nunc, a sodales."
    // }
    // NOTE: since it's not just the plain text but there is a checkbox I put it as a separate component, not the part of the map
  ],
};

export const promoPage = {
  text: "Based on your survey data and genetic sequencing information, our algorithm can developed an entirely unique supplement product to you. This bespoke supplement contains only ingrendients and compounds that are exactly aligned with your biology (with respect to dosage and product) and only those have been clinically validated through rigorous (and replicated) scientific research to have a significant affect on physical and mental and performance.",
  actionBtn1: "some text",
  actionBtn2: "some text",
};

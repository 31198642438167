import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useMemo,
  useRef
} from "react";
import axios from "axios";
import config from "../config";
import { SORT } from "./variables";

interface ContextType {
  sortOrder: string;
  report: Report;
  setSortOrder: (value: string) => void;
  isExpandedInfoHidden: boolean;
  handleToggleExpandable: (flag?: boolean | undefined) => void;
  setIsOpenPersonalSup: (flag: boolean | undefined) => void;
  isOpenPersonalSup: boolean;
  setUserId: (userId: string) => void;
  calculateReport: () => void;
}

export const Context = createContext<ContextType>({
  sortOrder: SORT.ALPHA,
  report: { effects: [], products: [] },
  setSortOrder: () => {},
  isExpandedInfoHidden: true,
  isOpenPersonalSup: false,
  setIsOpenPersonalSup: () => {},
  handleToggleExpandable: () => {},
  setUserId: () => {},
  calculateReport: () => {},
});

interface Report {
  effects: Array<any>;
  products?: Array<any>;
}

interface ContextProviderProps {
  children: ReactNode;
}

const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [isExpandedInfoHidden, setIsExpandedInfoHidden] = useState(true);
  const [sortOrder, setSortOrder] = useState<string>(SORT.ALPHA);
  const [isOpenPersonalSup, setIsOpenPersonalSup] = useState(false);
  const [report, setReport] = useState<Report>({ effects: [], products: [] });
  const [userId, setUserId] = useState<string | null>(null);

  const handleToggleExpandable = useCallback(
    (flag?: boolean) => {
      setIsExpandedInfoHidden(
        flag !== undefined ? flag : !isExpandedInfoHidden
      );
    },
    [isExpandedInfoHidden]
  );

  const handleSetIsOpenPersonalSup = useCallback((flag?: boolean) => {
    setIsOpenPersonalSup(flag ?? false);
  }, []);

  const isFetchingRef = useRef(false);

  const calculateReport = useCallback(() => {
    if (isFetchingRef.current) return; // Prevent duplicate requests

    isFetchingRef.current = true; // Set flag to indicate request in progress
    axios
      .post(`${config.apiBaseURL}/calculate`, null, { withCredentials: true })
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.error("Error calculating report:", error);
      })
      .finally(() => {
        isFetchingRef.current = false; // Reset flag
      });
  }, []);

  useEffect(() => {
    if (userId) {
      axios
        .get(`${config.apiBaseURL}/users/${userId}`, { withCredentials: true })
        .then((response) => {
          const selectedUser = response.data;
          const sortedEffects = selectedUser.effects;
          setReport({
            effects: sortedEffects,
            products: selectedUser.products || [],
          });
        })
        .catch((error) => {
          console.error("Error fetching user report:", error);
        });
    }
  }, [userId]);

  //@ts-ignore
  function normalizeDataForDiff(data) {
    // Desired key order for the top-level effect object
    const effectKeyOrder = [
      "identifier",
      "name",
      "score",
      "severity",
      "order",
      "description",
      "meaning",
      "output_text",
      "snps"
    ];

    // Desired key order for each SNP
    const snpKeyOrder = [
      "rs_id",
      "title",
      "overview",
      "genotype"
    ];

    // Desired key order for each product
    const productKeyOrder = [
      "name",
      "dosage",
      "measurement_unit",
      "justification",
      "links"
    ];
  //@ts-ignore
    // Reorder keys in an object according to a specified list (ignore extra keys)
    function reorderKeys(obj, keyOrder) {
      const result = {};
      for (const key of keyOrder) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          //@ts-ignore
          result[key] = obj[key];
        }
      }
      return result;
    }

    // Sort and reorder a single effect object
    //@ts-ignore
    function reorderEffect(effect) {
      // Sort the snps by rs_id (if present)
      const sortedSnps = (effect.snps || [])
        .slice()
      //@ts-ignore

        .sort((a, b) => (a.rs_id || "").localeCompare(b.rs_id || ""))
      //@ts-ignore

        .map(snp => reorderKeys(snp, snpKeyOrder));

      // Rebuild the effect with sorted snps
      const tempEffect = { ...effect, snps: sortedSnps };

      // Reorder the effect's top-level keys
      return reorderKeys(tempEffect, effectKeyOrder);
    }
      //@ts-ignore

    // Sort and reorder a single product object
    function reorderProduct(product) {
      return reorderKeys(product, productKeyOrder);
    }

    // 1) Sort effects by identifier, reorder each effect
    const sortedEffects = (data.effects || [])
      .slice()
      //@ts-ignore

      .sort((a, b) => (a.identifier || "").localeCompare(b.identifier || ""))
      .map(reorderEffect);

    // 2) Sort products by name, reorder each product
    const sortedProducts = (data.products || [])
      .slice()
      //@ts-ignore

      .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
      .map(reorderProduct);

    return {
      effects: sortedEffects,
      products: sortedProducts
    };
  }

  console.log('report: ', normalizeDataForDiff(report))

  const contextValue = useMemo(
    () => ({
      sortOrder,
      setSortOrder,
      isExpandedInfoHidden,
      handleToggleExpandable,
      isOpenPersonalSup,
      setIsOpenPersonalSup: handleSetIsOpenPersonalSup,
      report,
      setUserId,
      calculateReport,
    }),
    [sortOrder, isExpandedInfoHidden, isOpenPersonalSup, report]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export default ContextProvider;

import React, { useState } from "react";
import axios from "axios";
import config from "../../../config";
import { useQuery, useMutation, useQueryClient } from "react-query";
import classNames from "classnames";
import { EffectsConfigEditor } from "./EffectsConfigEditor";
import { Alert, Snackbar } from "@mui/material";

// 1) Fetch the config from /effects_config
const fetchEffectsConfig = async () => {
  const { data } = await axios.get(`${config.apiBaseURL}/effects_config`, {
    withCredentials: true, // if needed
  });
  if (!data.success) {
    throw new Error(data.message || "Could not fetch config");
  }
  return data.config; // The large JSON object
};

// 2) MyoEngine component
export const MyoEngine = () => {
  const [searchName, setSearchName] = useState("");

  // For error handling in a Snackbar
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // a) useQuery to load the config on mount
  const {
    data: configData, // the large JSON
    isLoading,
    isError,
    error,
  } = useQuery("effectsConfig", fetchEffectsConfig);

  // b) useMutation for updating
  const queryClient = useQueryClient();
  const updateConfigMutation = useMutation(
    async (updatedConfig) => {
      // Make the PUT request to /effects_config
      const { data } = await axios.put(
        `${config.apiBaseURL}/effects_config`,
        { config: updatedConfig },
        { withCredentials: true } // if your server requires credentials/cookies
      );
      if (!data.success) {
        throw new Error(data.message || "Could not update config");
      }
      return data;
    },
    {
      onSuccess: () => {
        // Optionally refetch or do other logic
        queryClient.invalidateQueries("effectsConfig");
        alert("Config saved successfully!");
      },
      onError: (error) => {
        // Show error in Snackbar
        setErrorSnackbarOpen(true);
        setErrorMessage(error.response?.data?.message || error.message);
      },
    }
  );

  // c) Handler passed to EffectsConfigEditor, calls mutateAsync
  const handleSave = async (updatedConfig) => {
    try {
      await updateConfigMutation.mutateAsync(updatedConfig);
      // onSuccess inside the mutation handles the alert already
    } catch (err) {
      // If you also want to do something here,
      // you could handle additional logic. Otherwise, it's all in onError.
    }
  };

  // Render states
  if (isLoading) {
    return <div>Loading config...</div>;
  }
  if (isError) {
    return (
      <div style={{ color: "red" }}>
        <strong>Error loading config:</strong> {error?.message}
      </div>
    );
  }

  // If we have data, render it
  return (
    <div className={classNames("LeftDiv", "admin-wrap")}>
      <h2 className="basic-page-header">Myo Engine</h2>

      <div className={classNames("basic-section", "")}>
        <div>
          <div className="search-container">
            <input
              className="search-container-input"
              type="text"
              placeholder="Search"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
            />
          </div>
          {/* Render the editor if configData is available */}
          {configData && (
            <EffectsConfigEditor config={configData} onSave={handleSave} />
          )}
        </div>
      </div>

      {/* Error Snackbar (Material UI) */}
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

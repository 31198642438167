import { FC, useState } from "react";
import BigCard from "./BigCard";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import { bigCardsContent, smallCardsContent } from "./constants";
import classNames from "classnames";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { useNavigate } from "react-router-dom";
import SmallCard from "./SmallCard";
import EnforcePasswordDialog from "./EnforcePasswordDialog";
import GeneticTestingKitCard from "./GeneticTestingKitCard";

interface Props {}

const Home: FC<Props> = ({}) => {
  const { data: currentUser } = useCurrentUser();
  const name = currentUser?.user?.first_name;
  const isNewUser = currentUser?.user?.enforce_password;
  const questionnaireResponse = !!currentUser?.user?.questionnaire_response;
  const isUnlocked: Record<string, boolean> = {
    questionnaire: true,
    dashboard:
      currentUser?.user?.has_report &&
      currentUser?.user?.has_confirmed_how_to_read,
    supplement:
      currentUser?.user?.has_report &&
      currentUser?.user?.has_confirmed_how_to_read,
    how_to_read: questionnaireResponse,
  };
  const isKitCompleted = currentUser?.user?.completed_kit;
  const hasReport = currentUser?.user?.has_report;

  const navigate = useNavigate();

  const [enforcePassword, setEnforcePassword] = useState(isNewUser || false);

  const handleOpenPersonalSupplement = () => {
    navigate("/dashboard", { state: { openDrawer: true } });
  };

  const updatedBigCardsContent = bigCardsContent.map((item) => {
    if (item.link === "questionnaire" && questionnaireResponse) {
      return {
        ...item,
        title: "Edit Your\n Health Questionnaire",
        text: "Edit your precision health questionnaire which collects information on your health, habits, and training",
        buttonText: "Edit Here",
      };
    }
    return item;
  });

  return (
    <>
      <div className="home-wrap">
        <div className="home-titles-wrap">
          <h2 className="home-title">Welcome to Myoform</h2>
          <span
            className={classNames("home-subtitle-greetings", "home-subtitle")}
          >
            Hi {name}!
          </span>
        </div>
        <div className={classNames("big-cards", "cards-wrap-big-cards")}>
          {!hasReport && (
            <GeneticTestingKitCard isKitCompleted={isKitCompleted} />
          )}
          {updatedBigCardsContent.map((item, index) => (
            <BigCard
              isUnlocked={isUnlocked[item.link]}
              page={item.link}
              text={item.text}
              title={item.title}
              isSupplementTile={item.isSupplementTile}
              isFirstOne={index === 0 || index === 1}
            >
              {
                <ButtonWithIcon
                  classNameOverWrite={classNames(
                    index !== 0 && "locked",
                    "big-card-footer-button",
                    item.link === "dashboard" && "big-card-dash-btns"
                  )}
                  onClick={() =>
                    item.link === "dashboard"
                      ? handleOpenPersonalSupplement()
                      : navigate(item.link)
                  }
                  text={item.buttonText}
                  disabled={!isUnlocked[item.link]}
                  classNameIconOverWrite={
                    !isUnlocked[item.link]
                      ? "locked-button-icon-black"
                      : index !== 0
                      ? "right-arrow-dark"
                      : ""
                  }
                />
              }
            </BigCard>
          ))}
        </div>
        <span className="home-subtitle">Coming Soon</span>
        <div className={classNames("small-cards", "cards-wrap")}>
          {smallCardsContent.map((item) => (
            <SmallCard text={item.text} icon={item.type} key={item.type} />
          ))}
        </div>
      </div>

      <EnforcePasswordDialog
        enforcePassword={enforcePassword}
        setEnforcePassword={setEnforcePassword}
      />
    </>
  );
};

export default Home;

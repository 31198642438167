import classNames from "classnames";
import AuthFooter from "./AuthFooter";
import AuthHeader from "./AuthHeader";
import { useAlternativeBackground } from "./hooks";
import { useCallback, useEffect, useState } from "react";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { alternativeBackgrounds } from "./constants";
import useResponsive from "../../hooks/useResponsive";
import axios, { AxiosError } from "axios";
import config from "../../config";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Snackbar } from "@mui/material";
import logoMini from "../../assets/logo-mini.svg";

interface SignUpProps {}

const resetPasswordApi = async (payload: { token: string; password: string }) => {
  const { data } = await axios.post(
    `${config.apiBaseURL}/auth/set-user-password`,
    payload,
    { withCredentials: true }
  );
  return data;
};

const validateTokenApi = async (params: { token: string }) => {
  const { data } = await axios.get(`${config.apiBaseURL}/auth/validate_token`, {
    withCredentials: true,
    params,
  });
  return data;
};

const SignUp: React.FC<SignUpProps> = () => {
  const { isDesktop, isMobile } = useResponsive();
  const alternativeBackground = useAlternativeBackground(alternativeBackgrounds);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const [isFormOpened, setIsFormOpened] = useState(false);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const [errors, setErrors] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [snackBarError, setSnackBarError] = useState<string | null>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // --- OTP-related states (added) ---
  const [requiresOTP, setRequiresOTP] = useState(false);
  const [userIdForOTP, setUserIdForOTP] = useState<number | null>(null);
  const [otpCode, setOtpCode] = useState("");
  // --- End OTP-related states ---

  const resetPasswordMutation = useMutation(resetPasswordApi, {
    onSuccess: (data) => {
      queryClient.setQueryData("currentUser", (prevData: any) => ({
        ...prevData,
        authenticated: true,
        user: data,
      }));
      navigate("/");
    },
    onError: (error: any) => {
      setSnackBarError(error.response?.data?.message || "Something went wrong");
      setTimeout(() => {
        navigate("/sign-in");
      }, 5000);
    },
  });

  const validateTokenMutation = useMutation(validateTokenApi, {
    onSuccess: (data) => {
      setFormData(() => ({
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        password: "",
        confirm_password: "",
      }));
    },
    onError: (error: any) => {
      setSnackBarError(error.response?.data?.message || "Something went wrong");
      setTimeout(() => {
        navigate("/sign-in");
      }, 5000);
    },
  });

  const onCreateAccountClick = useCallback(() => {
    setIsFormOpened(true);
  }, [isFormOpened]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const newErrors: string[] = [];
    if (!formData.first_name) newErrors.push("First name is required.");
    if (!formData.last_name) newErrors.push("Last name is required.");
    if (!formData.email) {
      newErrors.push("Email is required.");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.push("Email address is invalid.");
    }
    if (formData.password.length < 7)
      newErrors.push("Password must be at least 7 characters long.");
    if (formData.password !== formData.confirm_password)
      newErrors.push("Passwords do not match.");

    setErrors(newErrors);
    return newErrors.length === 0;
  };

  // Normal registration mutation
  const registerMutation = useMutation(
    async (newUser: typeof formData & { otp_code?: string }) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/auth/register`,
        newUser,
        { withCredentials: true }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        // If OTP is required, show the OTP card
        if (data.message === "OTP_REQUIRED") {
          setRequiresOTP(true);
          setUserIdForOTP(data.user_id);
          // Optionally store the formData if you need it again
          setSuccessMessage(null);
          setErrorMessage(null);
        } else {
          // Normal registration success
          setSuccessMessage("Account created successfully!");
          queryClient.setQueryData("currentUser", {
            authenticated: true,
            user: data,
          });
          navigate("/");
        }
      },
      onError: (error: any) => {
        if (axios.isAxiosError(error)) {
          setErrorMessage(
            error.response?.data?.message ||
              "An error occurred during registration."
          );
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
        setSuccessMessage(null);
      },
    }
  );

  // Function to handle final OTP verification (similar to login)
  const verifyOtp = () => {
    if (userIdForOTP) {
      registerMutation.mutate({
        ...formData,
        otp_code: otpCode, // add the OTP code
      });
    }
  };

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) e.preventDefault();
    if (validateForm()) {
      if (token) {
        // If there's a token, it's a reset password flow
        resetPasswordMutation.mutate({ token, password: formData.password });
      } else {
        // Otherwise, do normal registration
        registerMutation.mutate(formData);
      }
    }
  };

  useEffect(() => {
    if (token) {
      setIsFormOpened(true);
      validateTokenMutation.mutate({ token });
    }
  }, [token]);

  // --- OTP UI: If OTP is required, display only the OTP card ---
  if (requiresOTP && userIdForOTP) {
    return (
      <div
        className={classNames(
          "sign-up-wrap",
          isDesktop
            ? alternativeBackground
            : isFormOpened
            ? "sign-up-wrap-hide-gradient"
            : alternativeBackgrounds[0],
          isFormOpened && isMobile && "no-content"
        )}
      >
        <AuthHeader toSignUp={true} />
        <div className="sign-up-content">
          <div className="sign-in-main otp-wrapper">
            <div className="otp-card">
              <h2 className="otp-heading">Enter the code we emailed you</h2>
              <p className="otp-subtext">
                We sent a 6-digit code to your email address. Please enter it below to continue.
              </p>
              <div className="otp-input-container">
                <input
                  className="otp-input"
                  type="text"
                  placeholder="6-digit code"
                  value={otpCode}
                  onChange={(e) => setOtpCode(e.target.value)}
                />
              </div>
              <div className="otp-buttons">
                <button className="otp-button" onClick={verifyOtp}>
                  Verify Code
                </button>
                <button
                  className="otp-button otp-button-secondary"
                  onClick={() => {
                    // Reset OTP flow, return to normal sign-up form
                    setRequiresOTP(false);
                    setUserIdForOTP(null);
                    setOtpCode("");
                  }}
                >
                  cancel
                </button>
              </div>
            </div>
          </div>

          {/* Mobile-only logo at the bottom */}
          <div className="mobile-logo-container">
            <img src={logoMini} alt="Myoform logo" className="mobile-logo" />
          </div>

          <Snackbar
            open={!!snackBarError}
            autoHideDuration={5000}
            onClose={() => setSnackBarError(null)}
          >
            <Alert onClose={() => setSnackBarError(null)} severity="error">
              {snackBarError}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  }
  // --- End OTP UI ---

  // Normal sign-up UI
  return (
    <div
      className={classNames(
        "sign-up-wrap",
        isDesktop
          ? alternativeBackground
          : isFormOpened
          ? "sign-up-wrap-hide-gradient"
          : alternativeBackgrounds[0],
        isFormOpened && isMobile && "no-content"
      )}
    >
      <AuthHeader toSignUp={true} />
      <div
        className={classNames(
          "sign-up-content",
          isFormOpened && "sign-up-wrap-with-form"
        )}
      >
        <div className="sign-up-header">
          {!isFormOpened ? (
            <div className={classNames("sign-up-logo", "auth-mobile-only")} />
          ) : (
            <div className="sign-in-logo" />
          )}
          {!isFormOpened && (
            <h1>
              Perform to your
              <br />
              potential.
            </h1>
          )}
        </div>
        {isFormOpened && (
          <div className={classNames("sign-up-form-wrap")}>
            <h3 className="sign-up-form-title">Create Account</h3>
            <span className="sign-up-form-subtitle">Let's start performing</span>
            <form className="sign-up-form" onSubmit={handleSubmit}>
              <fieldset className="sign-up-form-names">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                  disabled={!!token}
                />
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                  disabled={!!token}
                />
              </fieldset>
              <fieldset className="sign-up-form-credentials">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={!!token}
                />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <input
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm password"
                  value={formData.confirm_password}
                  onChange={handleInputChange}
                />
              </fieldset>
              {errors.length > 0 && (
                <div className="sign-up-errors">
                  {errors.map((error, index) => (
                    <p key={index}>{error}</p>
                  ))}
                </div>
              )}
              {successMessage && (
                <div className="sign-up-success">{successMessage}</div>
              )}
              {errorMessage && (
                <div className="sign-up-error">{errorMessage}</div>
              )}
              <ButtonWithIcon
                classNameOverWrite={classNames(
                  "auth-desktop-only",
                  "auth-btn-sign-up"
                )}
                text="Sign Up"
                onClick={handleSubmit}
              />
            </form>
          </div>
        )}

        {!isFormOpened && (
          <button
            className={classNames(
              "button",
              "auth-btn",
              "auth-create-acc-btn",
              "auth-desktop-only"
            )}
            onClick={onCreateAccountClick}
          >
            Create Account
          </button>
        )}
        <AuthFooter
          isFormOpened={isFormOpened}
          buttonStyleOverwrite={isFormOpened ? "auth-btn-accent" : ""}
          onButtonClick={isFormOpened ? handleSubmit : onCreateAccountClick}
          hideMainButton={true}
          toSignUp={true}
          textOverwrite={isFormOpened ? "Sign Up" : ""}
        />
      </div>

      {/* Error Snackbar */}
      <Snackbar
        open={!!snackBarError}
        autoHideDuration={5000}
        onClose={() => setSnackBarError(null)}
      >
        <Alert onClose={() => setSnackBarError(null)} severity="error">
          {snackBarError}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignUp;

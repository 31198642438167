import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import "./geneticTestingInstruction.css";
import prePaidEnvelope from "../../assets/pre-paid-envelope.png";
import testingKit from "../../assets/genetic-testing-kit.png";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import config from "../../config";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import classNames from "classnames";
import CustomCheckbox from "../CustomCheckbox/CustomCheckbox";
import { useCurrentUser } from "../Auth/UseCurrentUser";
const VIDEO_LINK = "https://www.youtube.com/embed/M1CUunnOzhQ?rel=0";

const GeneticTestingInstructions: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: currentUser } = useCurrentUser();

  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isKitCompleted = currentUser?.user?.completed_kit;

  const updateUserMutation = useMutation(
    async (payload: { completed_kit: boolean }) => {
      const { data } = await axios.put(
        `${config.apiBaseURL}/auth/user`,
        payload,
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("currentUser", (prevData: any) => ({
          ...prevData,
          user: { ...prevData?.user, completed_kit: true },
        }));
        navigate("/");
      },
      onError: (error: any) => {
        setErrorSnackbarOpen(true);
        setErrorMessage(
          error?.response?.data?.message || "Something went wrong"
        );
      },
    }
  );

  const handleSubmit = async () => {
    await updateUserMutation.mutateAsync({ completed_kit: isChecked });
  };
  return (
    <div
      className={classNames(
        "LeftDiv",
        "how-to-read-wrap",
        "genetic-instruction"
      )}
    >
      {/* Header */}
      <h2 className="basic-page-header">Genetic Testing Instructions</h2>

      {/* Inside Your Package section */}
      <section id="insidePackage" className="basic-section">
        <h2 className="basic-page-header">Inside your package</h2>
        <p>Inside your package, you’ll find two main components:</p>
        <div className="package-images">
          <div className="package-item">
            <p>
              <b>A pre-paid envelope</b> <br /> (Complete with an invoice and
              label attached to the outside, DO NOT REMOVE THESE)
            </p>
            <img src={prePaidEnvelope} alt="Pre-paid envelope" />
          </div>
          <div className="package-item">
            <p>
              <b>A salivary sampling kit</b>
              <br />
              (Inside the box you’ll find a generic tube and funnel for sample
              collection)
            </p>
            <img src={testingKit} alt="Salivary sampling kit" />
          </div>
        </div>
      </section>

      {/* Step 1: Providing Your Sample */}
      <section id="step1" className="basic-section">
        <h2 className="basic-page-header">
          <b>Step 1: </b>
          <span>Providing your sample</span>
        </h2>
        <p>
          Your provided genetic testing kit will provide a set of instructions
          to follow inside the plastic container.
          <br />
          Alternatively, we have provided a short video explainer to walk you
          through each step of the process.
        </p>
        <p>
          Please review this process carefully prior to providing your sample.
        </p>

        <div>
          <iframe
            className="video"
            src={VIDEO_LINK}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      {/* Step 2: Preparing for Postage */}
      <section id="step2" className="basic-section">
        <h2 className="basic-page-header">
          <b>Step 2: </b>
          <span>Preparing for postage</span>
        </h2>
        <p>
          Once your sample has been collected you may discard the funnel, insert
          the tube back into the plastic container, and insert the plastic
          container back into the white cardboard container.
        </p>
        <p>
          You should then insert the box and its contents into the pre-paid
          envelope provided and seal the bag.
        </p>
      </section>

      {/* Step 3: Returning Your Package */}
      <section id="step3" className="basic-section">
        <h2 className="basic-page-header">
          <b>Step 3: </b>
          <span>Returning your package</span>
        </h2>

        <p>
          To return your sample to our laboratory for analysis, free-of-charge -
          use the link below to find the closest UPS drop-off point to you:
        </p>

        <p>
          <Link to="https://www.ups.com/dropoff" target="_blank">
            https://www.ups.com/dropoff
          </Link>
        </p>

        <p>
          Please note: other postal carriers will not accept this package.
          Therefore do not post this package in a Royal Mail postbox or your
          sample may be lost.
        </p>
      </section>

      {/* Step 4: Notify Us */}
      <section id="step4" className="basic-section">
        <h2 className="basic-page-header">
          <b>Step 4: </b>
          <span>Notify us that your package has shipped</span>
        </h2>
        <div className="how-to-read-disclaimer-wrap">
          <CustomCheckbox
            text="Ticking this box will let us know that your package is on its way
          to our labs. We will notify you when your data is ready for
          review!"
            isCheckedStatus={isChecked}
            onClick={() => setIsChecked(!isChecked)}
            disabled={isKitCompleted}
          />
          <ButtonWithIcon
            classNameOverWrite="how-to-read-disclaimer-button"
            classNameIconOverWrite="save-btn-icon"
            text={isKitCompleted ? "Submitted" : "Submit"}
            disabled={!isChecked || isKitCompleted}
            onClick={handleSubmit}
          />
        </div>
      </section>

      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={5000}
        onClose={() => setErrorSnackbarOpen(false)}
        sx={{ marginLeft: "200px" }}
      >
        <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default GeneticTestingInstructions;

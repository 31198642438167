import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import {
  TextField,
  Snackbar,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import AuthFooter from "./AuthFooter";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";
import axios, { AxiosError } from "axios";
import config from "../../config";
import AuthHeader from "./AuthHeader";
import { useAlternativeBackground } from "./hooks";
import { alternativeBackgrounds } from "./constants";
import classNames from "classnames";
import ButtonWithIcon from "../ButtonWithIcon/ButtonWithIcon";
import { GoogleLogin } from "@react-oauth/google";
import logoMini from "../../assets/logo-mini.svg";

interface ErrorResponse {
  message: string;
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

  // --- OTP-related states (added) ---
  const [requiresOTP, setRequiresOTP] = useState(false);
  const [userIdForOTP, setUserIdForOTP] = useState<number | null>(null);
  const [otpCode, setOtpCode] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  // --- End OTP-related states ---

  const alternativeBackground = useAlternativeBackground(alternativeBackgrounds);

  const googleLoginMutation = useMutation(
    async (access_token: string) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/auth/google`,
        { access_token },
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData("currentUser", {
          authenticated: true,
          user: data,
        });
        navigate("/");
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const resetPasswordMutation = useMutation(
    async (email: string) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/reset-password`,
        { email },
        {
          withCredentials: true,
        }
      );
      return data;
    },
    {
      onSuccess: () => {
        setForgotPasswordOpen(false);
        alert("Password reset email sent. Please check your inbox.");
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  const handleForgotPassword = (email: string) => {
    resetPasswordMutation.mutate(email);
  };
  
  const loginMutation = useMutation(
    async (credentials: { email: string; password: string; otp_code?: string }) => {
      const { data } = await axios.post(
        `${config.apiBaseURL}/auth/login`,
        credentials,
        { withCredentials: true }
      );
      return data;
    },
    {
      onSuccess: (data) => {
        // If the backend requires OTP, it will respond with message "OTP_REQUIRED"
        if (data.message === "OTP_REQUIRED") {
          setRequiresOTP(true);
          setUserIdForOTP(data.user_id);
          // Save the email and password for re-submission along with the OTP.
          setEmailValue(data.email || emailValue);
          setPasswordValue(passwordValue);
        } else {
          queryClient.setQueryData("currentUser", {
            authenticated: true,
            user: data,
          });
          navigate("/");
        }
      },
      onError: (error: AxiosError<ErrorResponse>) => {
        setErrorSnackbarOpen(true);
        const message = error.response?.data.message || "An error occurred";
        setErrorMessage(message);
      },
    }
  );

  // Function to verify OTP
  const verifyOtp = () => {
    if (userIdForOTP) {
      loginMutation.mutate({
        email: emailValue,
        password: passwordValue,
        otp_code: otpCode,
      });
    }
  };

// Inside the OTP block:
if (requiresOTP && userIdForOTP) {
  return (
    <div className={classNames("sign-in-wrap", alternativeBackground)}>
      <AuthHeader toSignUp={false} />
      <div className="sign-in-content">
        <div className="sign-in-main otp-wrapper">
          <div className="otp-card">
            <h2 className="otp-heading">Enter the code we emailed you</h2>
            <p className="otp-subtext">
              We sent a 6-digit code to your email address. Please enter it below to continue.
            </p>
            <div className="otp-input-container">
              <input
                className="otp-input"
                type="text"
                placeholder="6-digit code"
                value={otpCode}
                onChange={(e) => setOtpCode(e.target.value)}
              />
            </div>
            <div className="otp-buttons">
              <button className="otp-button" onClick={verifyOtp}>
                Verify Code
              </button>
              <button
                className="otp-button otp-button-secondary"
                onClick={() => {
                  // Reset logic and navigate to normal login
                  setRequiresOTP(false);
                  setUserIdForOTP(null);
                  setOtpCode("");
                  navigate("/sign-in");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        {/* Mobile-only logo at the bottom */}
        <div className="mobile-logo-container">
          <img src={logoMini} alt="Myoform logo" className="mobile-logo" />
        </div>

        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}



  return (
    <div className={classNames("sign-in-wrap", alternativeBackground)}>
      <AuthHeader toSignUp={false} />
      <div className="sign-in-content">
        <div className="sign-in-main">
          <div className="sign-in-logo" />
          <h2>Welcome Back</h2>
          <span>Log in to your account</span>
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={object({
              email: string()
                .required("Please enter your email")
                .email("Invalid email"),
              password: string()
                .required("Please enter your password")
                .min(7, "Password must be at least 7 characters long"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              // Save email and password for OTP verification if needed.
              setEmailValue(values.email);
              setPasswordValue(values.password);
              loginMutation
                .mutateAsync(values)
                .finally(() => setSubmitting(false));
            }}
          >
            {({ errors, touched, values }) => (
              <Form className="sign-in-form">
                <Field
                  as={TextField}
                  name="email"
                  type="email"
                  placeholder="Email"
                  hiddenLabel
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.email && touched.email)}
                  helperText={touched.email && errors.email}
                />
                <Field
                  as={TextField}
                  name="password"
                  type="password"
                  placeholder="Password"
                  hiddenLabel
                  fullWidth
                  error={Boolean(errors.password && touched.password)}
                  helperText={touched.password && errors.password}
                />
                <ButtonWithIcon
                  type="submit"
                  classNameOverWrite={classNames("auth-desktop-only")}
                  text="Log In"
                  onClick={() => {
                    console.log("log-in action");
                  }}
                />
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    googleLoginMutation.mutate(
                      credentialResponse.credential as string
                    );
                  }}
                  onError={() => {
                    setErrorMessage("Login With Google Failed");
                    setErrorSnackbarOpen(true);
                  }}
                />
                <AuthFooter
                  onButtonClick={() => loginMutation.mutate(values)}
                  toSignUp={false}
                />
              </Form>
            )}
          </Formik>
          <span
            className="sign-in-password-restore"
            onClick={() => setForgotPasswordOpen(true)}
          >
            Forgot password?
          </span>
        </div>
        <Snackbar
          open={errorSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => setErrorSnackbarOpen(false)}
          sx={{ marginLeft: "200px" }}
        >
          <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
      <Dialog
        open={forgotPasswordOpen}
        onClose={() => setForgotPasswordOpen(false)}
      >
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address to receive a password reset link.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                const email = (e.target as HTMLInputElement).value;
                handleForgotPassword(email);
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setForgotPasswordOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              const email = (document.getElementById("email") as HTMLInputElement).value;
              handleForgotPassword(email);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SignIn;

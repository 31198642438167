import React, { useCallback } from "react";
import { useCurrentUser } from "../../components/Auth/UseCurrentUser";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import useResponsive from "../../hooks/useResponsive";
import NavBarActionBtns from "./NavBarActionBtns";
import NavBarMobileHeader from "./NavBarMobileHeader";
import LockedIcon from "../../assets/svgComponents/LockedIcon";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import config from "../../config";

interface NavLinksProps {
  onHiddenToggle?: () => void;
  onNotifClick?: () => void;
  onPersonalSupplement?: () => void;
  isDrawerOpened?: boolean;
}

const NavLinks: React.FC<NavLinksProps> = ({
  onHiddenToggle,
  onNotifClick,
  isDrawerOpened,
  onPersonalSupplement,
}) => {
  const { isBigDesktop, isDesktop } = useResponsive();

  const isMobileHeaderPropsPresent = onHiddenToggle !== undefined && onNotifClick !== undefined && isDrawerOpened !== undefined;

  const { data: currentUser } = useCurrentUser();
  const isQuestionnaireAnswered = !!currentUser?.user?.questionnaire_response || false;
  const hasReport = currentUser?.user?.has_report || false;
  const hasConfirmedHowToRead = currentUser?.user?.has_confirmed_how_to_read || false;
  const isAdminUser = currentUser?.user?.role === 'ADMIN' || false;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleDrawerLinkClick = useCallback(() => {
    if (onHiddenToggle && isDrawerOpened) onHiddenToggle();
  }, [isDrawerOpened, onHiddenToggle]);

  const logoutMutation = useMutation(
    async () => {
      await axios.post(`${config.apiBaseURL}/auth/logout`, null, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("currentUser", {
          authenticated: false,
          user: null,
        });
        navigate("/sign-in");
      },
    }
  );

  const handleLogout = async () => {
    await logoutMutation.mutateAsync();
  };

  return (
    <div className="nav-links-wrap">
      {!isBigDesktop && isMobileHeaderPropsPresent && (
        <NavBarMobileHeader
          onHiddenToggle={onHiddenToggle}
          onNotifClick={onNotifClick}
          isDrawerOpened={isDrawerOpened}
          isInsideDrawer={true}
        />
      )}
      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? classNames("NavItem", "active") : "NavItem"
        }
        onClick={handleDrawerLinkClick}
      >
        Home
      </NavLink>
      <NavLink
        to="/questionnaire"
        className={({ isActive }) =>
          isActive ? classNames("NavItem", "active") : "NavItem"
        }
        onClick={handleDrawerLinkClick}
      >
        Questionnaire
      </NavLink>
      <NavLink
        to="/how_to_read"
        className={({ isActive }) =>
          isActive
            ? classNames("NavItem", "active")
            : classNames("NavItem", !isQuestionnaireAnswered && "locked")
        }
        onClick={handleDrawerLinkClick}
      >
        {!isQuestionnaireAnswered && <LockedIcon />}
        Reading Your Report
      </NavLink>
      <NavLink
        to="/dashboard"
        className={({ isActive }) =>
          isActive
            ? classNames("NavItem", "active")
            : classNames(
                "NavItem",
                !(
                  isQuestionnaireAnswered &&
                  hasConfirmedHowToRead &&
                  hasReport
                ) && "locked"
              )
        }
        onClick={handleDrawerLinkClick}
      >
        {!(isQuestionnaireAnswered && hasConfirmedHowToRead && hasReport) && (
          <LockedIcon />
        )}
        Health Report
      </NavLink>
      {!isDesktop && (
        <>
          <span onClick={onPersonalSupplement} className={"NavItem"}>
            Personal Supplement
          </span>
          <div className="sub-links-wrap">
            {/* <NavLink
              to=""
              className="sub-links"
              onClick={handleDrawerLinkClick}
            >
              Privacy Policy
            </NavLink> */}
            {isAdminUser ?
            <NavLink
              to="admin"
              className="sub-links"
              onClick={handleDrawerLinkClick}
            >
              Admin
            </NavLink>
            : null}
            <NavLink
              to="settings"
              className="sub-links"
              onClick={handleDrawerLinkClick}
            >
              Settings
            </NavLink>
            <NavLink to="settings" className="sub-links" onClick={handleLogout}>
              Logout
            </NavLink>
          </div>
          <div className="nav-links-mobile-buttons">
            <NavBarActionBtns onDrawerLinkClick={handleDrawerLinkClick} />
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(NavLinks);

import { useLocation } from "react-router-dom";

export const useIsPage = (path = "") => {
  const { pathname } = useLocation();

  const isHome = pathname === "/";
  const isHowToRead = pathname === "/how_to_read";
  const isQuestionnaire = pathname === "/questionnaire";
  const isDashboard = pathname === "/dashboard";
  const isAdmin = pathname === "/admin";
  const isSettings = pathname === "/settings";
  const isMyoEngine = pathname === "/myoengine";
  const isCustomLocation = pathname === path;
  const isGeneticTestingInstruction =
    pathname === "/genetic-testing-instruction";

  return {
    isDashboard,
    isHowToRead,
    isQuestionnaire,
    isCustomLocation,
    isAdmin,
    isSettings,
    isMyoEngine,
    isHome,
    isGeneticTestingInstruction,
  };
};

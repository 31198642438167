import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import config from "../config";
import { useMutation, useQueryClient } from "react-query";
import { getActiveRequests } from "../axiosInterceptors";

const SessionTimeout = ({ timeout = 1200 }) => {
  const [timer, setTimer] = useState(timeout);
  const navigate = useNavigate();
  const events = useRef(["mousemove", "keydown", "click"]);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Reset the timer whenever there is user activity
    const resetTimer = () => {
      setTimer(timeout);
    };

    // Set up event listeners for user activity
    events.current.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    // Decrement the timer every second, unless a request is in flight
    const countdown = setInterval(() => {
      if (getActiveRequests() > 0) {
        // If there are active requests (e.g. file upload), skip or reset
        // Option A: Skip this second
        return;

        // Option B: Reset to full timeout if you want to ensure
        // the user won't be logged out mid-upload:
        // setTimer(timeout);
      }
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    // Clean up event listeners and interval on component unmount
    return () => {
      events.current.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearInterval(countdown);
    };
  }, [timeout]);

  useEffect(() => {
    // Log out the user when the timer reaches zero
    if (timer <= 0) {
      handleLogout();
    }
  }, [timer]);

  const logoutMutation = useMutation(
    async () => {
      await axios.post(`${config.apiBaseURL}/auth/logout`, null, {
        withCredentials: true,
      });
    },
    {
      onSuccess: () => {
        queryClient.setQueryData("currentUser", {
          authenticated: false,
          user: null,
        });
        navigate("/sign-in");
      },
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          // Handle the case where the user is already logged out
          navigate("/sign-in");
        }
      },
    }
  );

  const handleLogout = async () => {
    const currentUser = queryClient.getQueryData("currentUser");
    if (currentUser && currentUser.authenticated) {
      await logoutMutation.mutateAsync();
    } else {
      // If the user is already logged out, just navigate to the sign-in page
      navigate("/sign-in");
    }
  };

  return null; // This component doesn't need to render anything
};

export default SessionTimeout;

import React, { useState } from "react";
// import "./EffectsConfigEditor.css";

/**
 * Main editor component. 
 * Props:
 *   - config: the full config object, e.g. { effects: { "achillesInjuryRisk": {...}, ... } }
 *   - onSave: callback fired when the user hits "Save Changes"
 */
export function EffectsConfigEditor({ config, onSave }) {
  // Make a local copy so we can edit in the UI without mutating the parent immediately.
  const [localConfig, setLocalConfig] = useState(() => JSON.parse(JSON.stringify(config)) || {});

  if (!localConfig.effects) {
    return <div>No effects found in config.</div>;
  }

  // Helper to update a single effect in local state
  const handleEffectChange = (effectKey, updatedEffect) => {
    setLocalConfig((prev) => {
      const newConfig = { ...prev, effects: { ...prev.effects } };
      newConfig.effects[effectKey] = updatedEffect;
      return newConfig;
    });
  };

  // Called when the user wants to save the entire updated config
  const handleSave = () => {
    onSave && onSave(localConfig);
  };

  const effectKeys = Object.keys(localConfig.effects);

  return (
    <div className="effects-config-editor">
      <h2>Effects Config Editor</h2>
      {effectKeys.map((effectKey) => {
        const effectObj = localConfig.effects[effectKey];
        return (
          <CollapsibleEffect
            key={effectKey}
            effectKey={effectKey}
            effectObj={effectObj}
            onChange={(updated) => handleEffectChange(effectKey, updated)}
          />
        );
      })}

      <button className="save-button" onClick={handleSave}>
        Save Changes
      </button>
    </div>
  );
}

/**
 * A collapsible panel for a single effect.
 * Shows fields for name, identifier, severityThresholds, plus a sub-editor for consideredSNPs.
 */
function CollapsibleEffect({ effectKey, effectObj, onChange }) {
  const [collapsed, setCollapsed] = useState(true);

  // Called when any field changes. We pass an entire updated effect object up.
  const updateField = (fieldPath, value) => {
    // We'll clone the effect, modify the relevant piece, and pass up
    const newEffect = structuredClone(effectObj); // or deep clone using other means if not supported
    // We can handle a simple top-level field or a dotted path
    if (fieldPath.includes(".")) {
      const [objKey, subKey] = fieldPath.split(".");
      newEffect[objKey][subKey] = value;
    } else {
      newEffect[fieldPath] = value;
    }
    onChange(newEffect);
  };

  const toggleCollapsed = () => setCollapsed(!collapsed);

  return (
    <div className="collapsible-effect">
      <div className="effect-header" onClick={toggleCollapsed}>
        <strong>{effectObj.name}</strong> ({effectKey})
        <span style={{ float: "right" }}>{collapsed ? "▼" : "▲"}</span>
      </div>

      {!collapsed && (
        <div className="effect-body">
          <div className="form-row">
            <label>Name:</label>
            <input
              type="text"
              value={effectObj.name || ""}
              onChange={(e) => updateField("name", e.target.value)}
              disabled={true}
            />
          </div>

          <div className="form-row">
            <label>Identifier:</label>
            <input
              type="text"
              value={effectObj.identifier || ""}
              onChange={(e) => updateField("identifier", e.target.value)}
              disabled={true}
            />
          </div>

          {effectObj.severityThresholds && (
            <>
              <h4>Severity Thresholds</h4>
              <div className="form-row">
                <label>Low:</label>
                <input
                  type="number"
                  value={effectObj.severityThresholds.low}
                  onChange={(e) =>
                    updateField("severityThresholds.low", parseFloat(e.target.value) || 0)
                  }
                />
              </div>
              <div className="form-row">
                <label>Medium:</label>
                <input
                  type="number"
                  value={effectObj.severityThresholds.medium}
                  onChange={(e) =>
                    updateField("severityThresholds.medium", parseFloat(e.target.value) || 0)
                  }
                />
              </div>
            </>
          )}

          {/* Editor for consideredSNPs */}
          {effectObj.consideredSNPs && (
            <>
              <h4>Considered SNPs</h4>
              <ConsideredSNPsEditor
                snps={effectObj.consideredSNPs}
                onChange={(updatedSNPs) => {
                  const clone = structuredClone(effectObj);
                  clone.consideredSNPs = updatedSNPs;
                  onChange(clone);
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}

/**
 * A small table-like editor for the "consideredSNPs" object, 
 * which looks like:
 *   {
 *     "rs12722": { "CC": -12.5, "CT": 25, ... },
 *     "rs679620": { "CC": 10, "CT": 25, ... },
 *     ...
 *   }
 */
function ConsideredSNPsEditor({ snps, onChange }) {
  // Helper: update the genotype map for a specific SNP
  const handleGenotypeChange = (snpKey, genotype, newValue) => {
    const newSNPs = structuredClone(snps);
    newSNPs[snpKey][genotype] = parseFloat(newValue) || 0; // convert to number
    onChange(newSNPs);
  };

  // Helper: update the SNP key name itself (rare case if you need to rename the rsID)
  // For simplicity, we’ll skip that unless needed.

  const snpKeys = Object.keys(snps);

  return (
    <div className="snps-editor">
      {snpKeys.map((snpKey) => {
        const genotypeMap = snps[snpKey];
        const genotypeKeys = Object.keys(genotypeMap);

        return (
          <div key={snpKey} className="snp-block">
            <h5>{snpKey}</h5>
            <table className="genotype-table">
              <thead>
                <tr>
                  <th>Genotype</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {genotypeKeys.map((genotype) => (
                  <tr key={genotype}>
                    <td>{genotype}</td>
                    <td>
                      <input
                        type="number"
                        value={genotypeMap[genotype]}
                        onChange={(e) => handleGenotypeChange(snpKey, genotype, e.target.value)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })}
    </div>
  );
}
